<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
</style>
<template lang="pug">
div.container-property
  .container
    .row.justify-content-center.align-items-center.async(style='height: 100vh' :class='{done:done}')
      .col-xl-5.col-lg-6.col-md-7
        h3.title.pb-3 새로운 작업공간 만들기
        .mb-4
          form.form(@submit.prevent='create()')
            .form-group
              label 이름
                span.required *
              input.form-control(type='text' v-model='form.name' autofocus @input='update_name' placeholder='작업공간 이름')

            .form-group
              label 주소
              input.form-control(type='text' v-model='form.subdomain' autofocus @focus='fill_subdomain' :placeholder='subdomain_default')
              strong: small https://app.noitaler.com/{{form.subdomain}}

            .text-right
              router-link.btn.btn-light.mr-2(:to='{name: `workspace.list`}') 뒤로가기
              button.btn.btn-primary(type='submit') 만들기

</template>

<script>

import {kebabCase} from 'lodash'

export default {
  name: 'index',
  components: {

  },
  computed: {

  },
  mounted() {
    this.load()
    this.track('화면_진입')
  },
  data() {
    return {
      done: false,
      templates: [],
      form: {
        template_id: '',
        name: '',
        subdomain: '',
      },
      subdomain_default: '',
    }
  },
  methods: {
    track(name, prop) {
      window.amplitude?.getInstance().logEvent(name, Object.assign({view:"로그인", subview:"워크스페이스생성"}, prop))
    },
    update_name(e) {
      this.form.name = e.target.value
      this.subdomain_default = kebabCase(this.form.name)
    },
    fill_subdomain() {
      if (!this.form.subdomain) {
        this.form.subdomain = this.subdomain_default
      }
    },
    async load() {
      try {
        const r = await this.$http.get('/v1/property/templates')
        this.templates = r.data.templates,
        this.done = true
      } catch (error) {
        console.log({error})
      }
    },
    async create() {
      try {
        // this.fill_subdomain()
        const r = await this.$http.post('/v2/property', {
          name: this.form.name,
          subdomain: this.form.subdomain || this.subdomain_default,
        })

        if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '실패')

        this.track('추가_워크스페이스', this.form)

        this.$router.push({
          name: 'layout',
          params: {
            property_subdomain: r.data.subdomain,
          }
        })
        this.$modal.show('dialog', {
          title: '🥳 알림',
          text: '새로운 워크스페이스를 추가했습니다.',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    }
    // async login() {
    //   try {
    //     const r = await this.$http.post('/v1/login/email', {
    //       ...this.form,
    //     })
    //     if (r?.data?.message != 'ok') throw new Error(r?.data?.message || '로그인 실패')

    //     const token = r.data.token

    //     window.localStorage.studio_token = token

    //     await this.$store.dispatch('session')

    //     this.$router.push({
    //       path: '/apps',
    //       query: {
    //         choose: 'Y',
    //       }
    //     })

    //   } catch (error) {
    //     alert(error.message)
    //   }
    // },
  },
}
</script>
